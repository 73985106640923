import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: ${props => props.show ? 'auto' : 'none'};
  background-color: ${props => props.show ? "rgba(78, 82, 91, 0.4)" : "rgba(78, 82, 91, 0.0)"};
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0 20px;
  transition: background-color .25s cubic-bezier(0.24, 0.01, 0.22, 1.3);
`