import React, { Component } from "react";
import Highcharts from 'highcharts'
import HighchartsRegression from 'highcharts-regression'
import HighchartsReact from 'highcharts-react-official'

HighchartsRegression(Highcharts);

class Chart extends Component {

  constructor(props) {
    super(props)
  }

  options = () => {
    return {
      title: {
        text: ''
      },
      caption: {
        text: this.props.caption,
        style: {
          color: '#1E1E1E',
          fontFamily: "'Helvetica Neue', Helvetica, sans-serif",
          fontSize: "14px",
          lineHeight: "26px",
        },
        margin: 25
      },
      tooltip: { enabled: false },      
      credits: {
        enabled: false,
      },
      chart: {
        type: 'line',
        animation: false,
        height: "250px",
      },
      plotOptions: {
        series: {
          animation: false
        }
      },      
      legend: {
        enabled: false,
      },  
      xAxis: {
        visible: false,
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        visible: true,
        title : {
          enabled: false,
        },
        labels: {
          enabled: true,
          step: 4,
          style: {
            fontFamily: "'Helvetica Neue', Helvetica, sans-serif",
            color: '#404040',
            fontWeight: "500"
          }
        },
        gridLineColor: '#EBF1F2',
        gridLineWidth: 1,
        plotLines: [{
          color: '#DDDDDD',
          width: 1,
          value: 0
        }],
      },  
      series: [
        {
          type: 'line',
          color:  'rgba(27,199,159,1)',
          data: this.props.lll_data, //[[0,1], [4,2], [5,3]],
          marker: {
            radius: 0
          },
        },
        {
          type: 'line',
          color:  'rgba(255,111,69,1)',
          data: this.props.non_lll_data, //[[0,1], [2,1.5], [3,2]],
          marker: {
            radius: 0
          },
        },        
      ],
    }
  }

  render() {

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={this.options()}
      />
    )
  }
}

export default Chart;
