import React, { Component } from 'react';
import { ButtonGreen, DeleteButton } from '@styles/buttons';
import { Error } from '@styles/typography';
import FRGSelect from '../FRGSelect'
import Api from '@utils/api'
import { LLL_STATUSES } from '@utils/utils'

const newPerson = () => ({
  child_id: "",
  date_of_birth: "",
  lll_status: ""
})

class AddPeople extends Component {

  constructor(props) {
    super(props)

    this.state = {
      people: [newPerson()],
      errors: [],
      message: "",
      previouslyDeleted: "",
    }
  }

  handleOnChange = (values, index) => {
    const newPeople = this.state.people.map((p,i) => i === index ? {...p, ...values} : p)
    const lp = newPeople[newPeople.length-1]
    if (lp.child_id !== "" && lp.date_of_birth !== "" && lp.lll_status !== "") {
      newPeople.push(newPerson())
    }
    this.setState({people: newPeople})
  }

  handleDelete = (index) => {
    this.setState({people: this.state.people.filter((p,i) => i !== index)})
  }

  handleSubmit = () => {
    const people = this.state.people.filter(p => p.child_id !== "")
    if (people.length > 0) {
      Api.post(`/people/add`, {people: people} )
        .then(result => {
          console.log('people', result, result.data)
          const previouslyDeleted = result.data.filter(p => p.previously_deleted)
          let previouslyDeletedMessage =  ""
          if (previouslyDeleted.length === 1) {
            previouslyDeletedMessage = `Please note that Young Person with ID ${previouslyDeleted[0].child_id} was previously on the system and deleted, please make sure you have their consent to be added back onto the system.`
          }
          if (previouslyDeleted.length > 1) {
            previouslyDeletedMessage = `Please note that the Young People with IDs ${previouslyDeleted.map(p => p.child_id).join(', ')} were previously on the system and deleted, please make sure you have their consent to be added back onto the system.`
          }
          this.setState({
            message: `Succesfully added ${people.length} ${people.length === 1  ? 'young person' : 'young people'}.`,
            previouslyDeleted: previouslyDeletedMessage,
            people: [newPerson()],
            errors: [],
          })
        })
        .catch(error => {
          this.setState({
            message: "Sorry, there was a problem, please check the errors below",
            errors: error.response.data.errors})
        })
      }
  }

  render() {
    const { message, previouslyDeleted, people, errors } = this.state

    return (
      <div className="flex-container">
        {message && <Error position="above"><p>{message}</p></Error>}
        {previouslyDeleted && <Error position="above"><p>{previouslyDeleted}</p></Error>}
        <table className="dashboard__table">
          <thead>
            <tr>
              <th className="col-30 align-left">Child ID</th>
              <th className="col-30 align-left">D.O.B.</th>
              <th className="col-30 align-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {people.map((person, index) => (
              <tr key={index}>
                <td className="col-30 align-left">
                  <input className="dashboard__table__input" type="text" value={person.child_id} placeholder="AA-BBB-CCC" onChange={e => this.handleOnChange({child_id: e.target.value}, index)} />
                  {errors[index] && errors[index].child_id && <Error position="under"><p>{errors[index].child_id}</p></Error>}
                </td>
                <td className="col-30 align-left">
                  <input className="dashboard__table__input" type="date" value={person.date_of_birth} placeholder="01/01/2001" onChange={e => this.handleOnChange({date_of_birth: e.target.value}, index)} />
                  {errors[index] && errors[index].date_of_birth && <Error position="under"><p>{errors[index].date_of_birth}</p></Error>}
                </td>
                <td className="col-30 align-left">
                  <div className="dashboard__table__field-with-delete">
                    <div className="dashboard__table__field-with-delete__field">
                      <FRGSelect
                        options={LLL_STATUSES}
                        value={LLL_STATUSES.find(s => s.value === person.lll_status) || null}
                        onChange={o => this.handleOnChange({lll_status: o.value}, index)}
                      />
                    </div>
                    {index !== people.length - 1 &&
                      <DeleteButton onClick={() => this.handleDelete(index)} />
                    }
                  </div>
                  {errors[index] && errors[index].status && <Error><p>{errors[index].status}</p></Error>}
                  {errors[index] && errors[index].lll_status && <Error position="under"><p>{errors[index].lll_status}</p></Error>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="frg-form__actions">
          <div className="frg-form__actions__right">
            <button onClick={() => {window.location.href = "/admin/people"}} className="button button--grey">Close Without Saving</button>
            <button onClick={() => this.handleSubmit()} type="submit" disabled={this.state.people.filter(p => p.child_id !== "").length === 0}className="button button--green">Add Young People</button>
          </div>
        </div>
      </div>
    )
  }
}

export default AddPeople;
