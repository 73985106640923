import React, { Component } from 'react';
import FRGSelect from '../FRGSelect';
import Api from '@utils/api'
import { LLL_STATUSES } from '@utils/utils'

const OPTIONS = [{value: 'all', label: 'All Young People'}].concat(LLL_STATUSES)

class LLLStatusSelect extends Component {

  onChange = (option) => {
    window.location.href = this.props.url + option.value
  }

  render() {
    return (
      <FRGSelect
        options={OPTIONS}
        value={OPTIONS.find(s => s.value === this.props.value)}
        onChange={option => this.onChange(option)}
      />
    )
  }
}

export default LLLStatusSelect;
