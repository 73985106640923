import React, { Component } from "react";
import Select, { components } from 'react-select'

class FRGSelect extends Component {

  render() {

    const DownArrow = () => {
      return <i className="icon icon--arrow--down--green" />;
    };

    const DropdownIndicator = props => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <DownArrow />
          </components.DropdownIndicator>
        )
      );
    };

    const customStyles = {
      container: (base) => ({
        ...base,
        minWidth: this.props.minWidth || 150,
        maxWidth: 400,
        flexGrow: 1
      }),
      control: (base, state) => ({
        ...base,
        width: '100%',
        border: state.isFocused ? 0 : 0,
        boxShadow: state.isFocused ? 0 : 0,
        fontFamily: 'Helvetica, sans-serif',
        fontSize: this.props.sizeLarge ? 17 : 13,
        height: 40,
        backgroundColor: this.props.noBG ? "transparent" : "#FFF",
      }),
      menu: base => ({
        ...base,
        border: 0,
        boxShadow: '0 4px 11px hsla(0, 0%, 0%, 0.1)'
      }),
      menuList: base => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
      }),
      indicatorSeparator: base => ({
        ...base,
        display: 'none'
      }),
      indicatorsContainer: base => ({
        ...base,
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#FFF' : state.isFocused ? '#3B3B3B' : '#3B3B3B',
        backgroundColor: state.isSelected ? '#00BC90' : state.isFocused ? 'rgba(0, 188, 144, 0.15)' : '#FFF',
        fontFamily: 'Helvetica, sans-serif',
        fontSize: 13,
        ':active': {
          backgroundColor: 'rgba(0, 188, 144, 0.15)'
        }
      })
    }

    return (
      <Select
        styles={customStyles}
        components={{ DropdownIndicator }}
        options={this.props.options}
        placeholder={this.props.placeholder}
        value={this.props.value}
        onChange={this.props.onChange}
        isDisabled={this.props.isDisabled}
      />
    )
  }
}

export default FRGSelect;
