export const QuestionnaireStages = (questionnaire) => {
  let stages = ["setup"]

  if (questionnaire) {
    if (questionnaire.meeting_count || questionnaire.remote) {
      stages.push("intro")
      stages.push("consent")
    }

    if (questionnaire.consent_statements && questionnaire.consent_frg) {
      stages.push("questions")      
    }      
  }

  return stages
}

export const ConnectionName = (connection, connections) => {
  if (connections.filter(c => c.name === connection.name).length > 1) {
    return `${connection.name} (${connection.description})`
  } else {
    return connection.name
  }
}

export const ConnectionNames = (connections) => {
  return connections.map(c => ConnectionName(c, connections))
}

const LLL_STATUSES = [
  {value: "receiving", label: "Receiving Lifelong Links"},
  {value: "not_receiving", label: "Not Receiving Lifelong Links"},
]

export { LLL_STATUSES }