import React, { Component } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class ActionModal extends Component {

  constructor(props) {
    super(props)
    this.triggerRef = React.createRef();
    this.state = {
      display: false,
      top: null,
      left: null
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
    window.removeEventListener('resize', this.handleResize)
  }  

  componentDidUpdate() {
    if (this.props.display !== this.state.display) {
      if (this.props.display) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  show = () => {
    const rect = this.triggerRef.current.getBoundingClientRect()

    const left = rect.left + rect.width
    const top = rect.top + rect.height + 10
    this.setState({display: true, top, left})
    disableBodyScroll(this.triggerRef.current, { reserveScrollBarGap: true } );
  }

  hide = () => {
    this.setState({display: false})
    enableBodyScroll(this.triggerRef.current);
  }

  handleResize = () => {
    if (this.state.display) {
      const rect = this.triggerRef.current.getBoundingClientRect()
      const left = rect.left + rect.width
      const top = rect.top + rect.height + 10
      this.setState({top, left})
    }
  }

  render() {
    const { display } = this.state;

    return (
      <div className="modal-trigger-wrapper">
        <div className={`trigger ${display ? 'active' : ''}`} ref={this.triggerRef}>
          {this.props.trigger}
        </div>

        <div className={`modal-mask ${display ? 'active' : ''}`} onClick={this.props.onClose}>
          <div className="modal-wrapper" style={{left: `${this.state.left}px`, top: `${this.state.top}px`}}>
              <div className="modal-container" onClick={e =>  e.stopPropagation() }>
                {display && this.props.children}
              </div>
          </div>
        </div>

      </div>  
    )
  }
}

export default ActionModal;
