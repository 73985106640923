import React, { Component } from 'react';
import FRGSelect from '../FRGSelect';
import Api from '@utils/api'

class PersonAssign extends Component {

  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value,
    }
  }

  render() {
    const { options } = this.props
    const { value } = this.state

    return (
      <div className="frg-person-assign">
        <input type="hidden" name="person[user_id]" value={value.value} />
        <div className="frg-person-assign__select">
          <div style={{width:300}}>
            <FRGSelect
              options={options}
              value={value}
              onChange={option => this.setState({value: option})}
            />
          </div>
        </div>
        <div className="frg-person-assign__control">
          <button type="button" className="button button--link button--link--green" onClick={() => this.setState({value: {value: "", label: "Unassigned"}}) }>Revoke Access</button>
        </div>
      </div>
    )
  }
}

export default PersonAssign;
