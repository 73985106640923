// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("@babel/polyfill")

import * as Sentry from '@sentry/browser';

if (!(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) {
  Sentry.init({
    dsn: "https://9dd3fc323fdb4c78b57246999c179c40@sentry.io/1426952"
  });
}

import Field from '@components/Field'
import AddPeople from '@components/AddPeople'
import QuestionVisualisation from '@components/QuestionVisualisation'
import PersonAssign from '@components/PersonAssign'
import AssignPeople from '@components/AssignPeople'
import LLLStatusSelect from '@components/LLLStatusSelect'
import RailsSelectInput from '@components/RailsSelectInput'
import CopyButton from '@components/CopyButton'
import RollKeysActions from '@components/RollKeysActions'
import FormSelectField from '@components/FormSelectField'
import Chart from '@components/admin/Chart'
import DashboardQuestions from '@components/admin/DashboardQuestions'
import QuestionChart from '@components/admin/QuestionChart'
import DashboardMiniChart from '@components/admin/DashboardMiniChart'
import ConnectionsTable from '@components/admin/ConnectionsTable'
import WebpackerReact from 'webpacker-react'
import elementClosest from "element-closest";
import ReportBuilder from '@components/ReportBuilder/ReportBuilder'

elementClosest(window);

WebpackerReact.setup({Field})
WebpackerReact.setup({AddPeople})
WebpackerReact.setup({QuestionVisualisation})
WebpackerReact.setup({PersonAssign})
WebpackerReact.setup({AssignPeople})
WebpackerReact.setup({LLLStatusSelect})
WebpackerReact.setup({RailsSelectInput})
WebpackerReact.setup({CopyButton})
WebpackerReact.setup({RollKeysActions})
WebpackerReact.setup({FormSelectField})
WebpackerReact.setup({Chart})
WebpackerReact.setup({DashboardQuestions})
WebpackerReact.setup({DashboardMiniChart})
WebpackerReact.setup({QuestionChart, ConnectionsTable})
WebpackerReact.setup({ReportBuilder})