import React, { Component } from "react";
import { ButtonSolidGreen, ButtonWhite, ButtonGreen, ButtonGreyTransparent, ButtonRed, ButtonRedConfirm } from '@styles/buttons';
import ReportBuilderIntro from './ReportBuilderIntro';
import ReportBuilderAddColumn from './ReportBuilderAddColumn';
import ReportBuilderSaveModal from './ReportBuilderSaveModal';
import Api from "@utils/api"
import FRGSelect from '@components/FRGSelect'
import ActionModal from '@components/ActionModal'

const typeOptions = [
  {
    value: 'council', 
    label: 'Council', 
    statistics: ['questionnaire_complete_count', 'questionnaire_incomplete_count', 'average_time_taken', 'average_connections_count'], 
    filters: [null, 'social_workers', 'coordinators', 'receiving_lll', 'not_receiving_lll', 'year', 'month']
  },
  {
    value: 'region', 
    label: 'Region', 
    statistics: ['questionnaire_complete_count', 'questionnaire_incomplete_count', 'average_time_taken', 'average_connections_count'], 
    filters: [null, 'social_workers', 'coordinators', 'receiving_lll', 'not_receiving_lll', 'year', 'month']
  },
  {
    value: 'month', 
    label: 'Month', 
    statistics: ['questionnaire_complete_count', 'average_time_taken', 'average_connections_count'], 
    filters: [null, 'social_workers', 'coordinators', 'receiving_lll', 'not_receiving_lll', 'council']
  },
  {
    value: 'time_since', 
    label: 'Time Since First Interview', 
    statistics: ['questionnaire_complete_count', 'average_time_taken', 'average_connections_count'], 
    filters: [null, 'social_workers', 'coordinators', 'receiving_lll', 'not_receiving_lll', 'council']
  },
]

const columnHeaderStatistics = {
  "questionnaire_complete_count": "Completed Interviews",
  "questionnaire_incomplete_count": "Incomplete Interviews",
  "average_time_taken": "Avg Completion Time (mins)",
  "average_connections_count": "Avg No Trusted Connections"
}

const columnHeaderFilters = (column) => {
  return {
    "social_workers": <><span>With</span> <span>Social Workers</span></>,
    "coordinators": <><span>With</span> <span>Coordinators</span></>,
    "receiving_lll": <><span>By</span> <span>Receiving LLL</span></>,
    "not_receiving_lll": <><span>By</span> <span>Not Receiving LLL</span></>,
    "council": <><span>For</span> <span>{column.council_name}</span></>,
    "year": <><span>For</span> <span>{column.year}</span></>,
    "month": <><span>For</span> <span>{column.month}</span></>
  }[column.filter]
}

class ReportBuilder extends Component {

  constructor(props) {
    super(props)
    const report = this.props.report || {};

    this.state = {
      loading: false,
      page: report.id ? 'table' : 'intro',
      addColumn: false,
      mode: 'display',
      saveModal: false,
      deleteModal: false,
      data: [],
      report: {
        id: report.id,
        name: report.name,
        report_type: report.report_type ? typeOptions.find(t => t.value === report.report_type) : typeOptions[0],
        columns: [],
      },
    }
  }

  async componentDidMount() {
    const { report } = this.props;
    if (report && report.id) {
      this.setState({loading: true})
      await this.fetchTypeData(report.report_type)
      for (let i = 0; i < (report.columns || []).length; i++) {
        await this.handleAddColumn(report.columns[i])
      }
      this.setState({loading: false})
    }
  }

  fetchTypeData = (type) => {
    return Api.get('/reports/report_type_data', { params: { type } })
      .then(response => {
        this.setState({ data: response.data })
      })
      .catch(error => {
        console.error(error)
      })
  }

  handleNext = (type) => {
    const { report } = this.state;
    this.setState({ report: { ...report, report_type: type }, page: 'table' })
    this.fetchTypeData(type.value)
  }

  handleAddColumn = (column) => {
    const { report } = this.state;

    // Get the column report data
    return Api.get('/reports/report_column', { params: { column: {...column, type: report.report_type.value } } } )
      .then(response => {
        this.setState({
          addColumn: false,
          report: {
            ...report,
            columns: [
              ...report.columns, 
              {
                ...column,
                data: _.keyBy(response.data, 'key')
              }
            ]
          }
        })    
      })
      .catch(error => {
        console.error(error)
        window.alert("Sorry, something went wrong")
      })
  }

  handleDeleteColumnAt = (index) => {
    const { report } = this.state;
    
    this.setState({
      report: {
        ...report,
        columns: report.columns.filter((c,i) => i !== index)
      }
    })
  }

  handleDelete = (index) => {
    const { report } = this.state;
    Api.delete(`/reports/${report.id}`)
    .then(response => {
      window.location = "/frg"
    })
    .catch(error => {
      console.error(error)
      window.alert("Sorry, something went wrong")
    })    
  }

  handleOnSave = (updatedReport) => {
    this.setState({
      report: {...this.state.report, ...updatedReport},
      mode: 'display'
    })
  }

  render() {
    const { page, report, addColumn, loading, saveModal, mode, deleteModal} = this.state;

    const format = (statistic, data) => {
      if (statistic === 'average_time_taken') {
        return Math.round(data['average_time_taken'])
      }
      if (statistic === 'questionnaire_complete_count' && report.report_type.value == 'time_since') {
        return <div>
          <span className="percentage">{data['percentage']}%</span>
          <span className="total">({data['questionnaire_complete_count']} in Total)</span>        
        </div>
      }
      return data[statistic]
    }

    return (
      <div>
        <div className="dashboard__back">
          {report && report.id && mode === 'display' && <a className="button button--back" href="/frg">Back to All</a>}
        </div>

        { page === 'intro' && <ReportBuilderIntro type={report.report_type} onNext={this.handleNext } options={typeOptions} /> }

        { page === 'table' && 
          <section>
            {!addColumn && 
              <div className="report-builder__header">
                <div className="left">
                  <h1>{report.name || "Create Custom Table"}</h1>
                  {(mode === 'edit' || !report.id) && 
                    <ButtonSolidGreen disabled={report.columns.length >= 3} onClick={() => this.setState({addColumn: true})}>
                      <span style={{marginRight: "8px"}}>Add Column</span> <i className="icon icon--plus--white"></i>
                    </ButtonSolidGreen>
                  }
                </div>

                {!report.id 
                  ? <div className="report-builder__header__actions">
                      <ButtonGreyTransparent onClick={() => {window.location = '/frg'}}>Close Without Saving</ButtonGreyTransparent>
                      <ReportBuilderSaveModal report={report} onSave={this.handleOnSave} />
                    </div>
                  : 
                    <div>
                      {mode === 'display'
                        ? <ButtonWhite border onClick={() => this.setState({mode: 'edit'})}>Edit</ButtonWhite>
                        : 
                          <div className="report-builder__header__actions">
                            <ActionModal 
                              display={deleteModal} 
                              trigger={<ButtonRed onClick={() => this.setState({deleteModal: true})}>Delete</ButtonRed>}
                              onClose={() => this.setState({deleteModal: false})}
                            >
                              <ButtonRedConfirm onClick={this.handleDelete}>Confirm</ButtonRedConfirm>
                            </ActionModal>

                            <ButtonGreyTransparent onClick={() => window.location = '/frg' }>Close Without Saving</ButtonGreyTransparent>

                            <ReportBuilderSaveModal report={report} onSave={this.handleOnSave} />
                          </div>
                      }
                    </div>
                }
              </div>
            }

            {addColumn && 
              <ReportBuilderAddColumn 
                report={report}
                onCancel={() => this.setState({addColumn: false})} 
                onAddColumn={this.handleAddColumn} 
              />}

            {loading
              ? <p className="report-builder__loading">Loading Report...</p>
              : <table className="report-builder__table">
                <thead>
                  <tr>
                    <th className="col-25 align-left sortable asc active">
                      {typeOptions.find(t => report.report_type.value === t.value).label}
                    </th>
                    {report.columns.map((c,i) => (
                      <th key={`th-${c.statistic}-${i}`} className="col-25 align-right">
                        <div className="report-column">
                          <div className="report-column__details">
                            <div className="statistic">{columnHeaderStatistics[c.statistic]} </div>
                            {c.filter && <div className="filter">{columnHeaderFilters(c)}</div>}
                          </div>
                          {(!report.id || mode === 'edit') 
                            ? <i className="icon icon--report--delete" onClick={() => this.handleDeleteColumnAt(i)}></i>
                            : <i className="icon">&nbsp;</i>
                          }
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((d, i) => (
                    <tr key={d.key}>
                      <td className="col-50 align-left">{d.label}</td>
                      {report.columns.map((c,i) => (
                        <td key={`td-${c.statistic}-${i}`} className="col-25 align-right">
                          { (c.data && c.data[d.key] && format(c.statistic, c.data[d.key]) ) || "-"  }
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            }

          </section>
        }
      </div>
    )
  }
}

export default ReportBuilder;
