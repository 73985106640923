import React, { Component } from 'react';
import { ButtonGreen, DeleteButton } from '@styles/buttons';
import moment from 'moment';

const AnswerIcon = ({answer, gradient1, gradient2}) => {
  return {
    "Rather not say":
      <div className="answer-icon">
        <i className="dot dot--grey"><span className="tooltip">Rather not say</span></i>
        {answer.common && <span className="gradient" style={{backgroundImage: `linear-gradient(180deg, ${gradient1} 0%, ${gradient2} 100%)`}}></span>}
      </div>,
    "Not at all":
      <div className="answer-icon">
        <i className="dot dot--red"><span className="tooltip">Not at all</span></i>
        {answer.common && <span className="gradient" style={{backgroundImage: `linear-gradient(180deg, ${gradient1} 0%, ${gradient2} 100%)`}}></span>}
      </div>,
    "Not really":
      <div className="answer-icon">
        <i className="dot dot--orange"><span className="tooltip">Not really</span></i>
        {answer.common && <span className="gradient" style={{backgroundImage: `linear-gradient(180deg, ${gradient1} 0%, ${gradient2} 100%)`}}></span>}
      </div>,
    "Undecided":
      <div className="answer-icon">
        <i className="dot dot--yellow"><span className="tooltip">Undecided</span></i>
        {answer.common && <span className="gradient" style={{backgroundImage: `linear-gradient(180deg, ${gradient1} 0%, ${gradient2} 100%)`}}></span>}
      </div>,
    "Probably":
      <div className="answer-icon">
        <i className="dot dot--light-green"><span className="tooltip">Probably</span></i>
        {answer.common && <span className="gradient" style={{backgroundImage: `linear-gradient(180deg, ${gradient1} 0%, ${gradient2} 100%)`}}></span>}
      </div>,
    "Definitely":
      <div className="answer-icon">
        <i className="dot dot--green"><span className="tooltip">Definitely</span></i>
        {answer.common && <span className="gradient" style={{backgroundImage: `linear-gradient(180deg, ${gradient1} 0%, ${gradient2} 100%)`}}></span>}
      </div>,
  }[answer.value] || answer.value
}

const gradientColors = {
  "Rather not say": "#fff",
  "Not at all": "#FF4545",
  "Not really": "#FF8E2A",
  "Undecided": "#F9EB02",
  "Probably": "#89DC00",
  "Definitely": "#07DB3E"
}

class QuestionVisualisation extends Component {

  constructor(props) {
    super(props)

    this.state = {
      showHistory: false,
    }
  }

  handleOnHistory = () => {
    this.setState({showHistory: !this.state.showHistory})
  }

  render() {
    const { user_type } = this.props
    const { question, answer_set_1, answer_set_2 } = this.props.question_set
    const { showHistory, colorsArray } = this.state;

    return (
      <div className={"visualisation__questionnaire__question " + (showHistory ? "history-visible" : "")}>
        <div className="question">
          <p>{ question.content }</p>
        </div>
        <div className="answer-set">
          <div className="answer-set__date">
            <span>{moment(answer_set_1.completed_at).format("MMM YYYY")}</span>
          </div>
          <div className="answer-set__answers">
            {answer_set_1.answers.map((answer, index) => (
              <div key={answer.id} className="answer">
                <AnswerIcon 
                  answer={answer}
                  gradient1={answer.common ? gradientColors[answer.value] : null}
                  gradient2={answer.common ? gradientColors[answer_set_2.answers[index].value] : null}
                />
                <span className="name">{answer.name}</span>
              </div>
            ))}
          </div>
          <div className="answer-set__connections">
            {answer_set_1.trusted_connection_count != null && (
              <div className="answer">
                <i className="icon icon--smiley"></i>  
                <span>{answer_set_1.trusted_connection_count}</span>
              </div>
            )}
          </div>
          <div className="answer-set__history">
            {answer_set_2 && user_type !== "coordinator" && (
              <button onClick={this.handleOnHistory} className={"button " + (showHistory ? "button--blue" : "button--outline button--outline--history")}>{showHistory ? 'OK' : 'History'}</button>
            )}
          </div>
        </div>
        { answer_set_2 && (
          <div className="answer-set answer-set--history">
            <div className="answer-set__date">
              <span>{moment(answer_set_2.completed_at).format("MMM YYYY")}</span>
            </div>
            <div className="answer-set__answers">
              {answer_set_2.answers.map(answer => (
                <div key={answer.id} className="answer">
                  <AnswerIcon answer={answer} />
                  {!answer.common && <span className="name">{answer.name}</span>}
                </div>
              ))}
            </div>
            <div className="answer-set__connections">
            {answer_set_2.trusted_connection_count != null && (
              <div className="answer">
                <i className="icon icon--smiley"></i>
                <span>{answer_set_2.trusted_connection_count}</span>
              </div>
            )}
            </div>
            <div className="answer-set__history"></div>
          </div>
        )}
      </div>
    )
  }
}

export default QuestionVisualisation;
