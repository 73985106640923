import React, { Component } from "react";
import { ButtonLargeSolidGreen, ButtonOption } from '@styles/buttons';
import ReportCouncil from '@images/report-builder/report_council.png';
import ReportRegion from '@images/report-builder/report_region.png';
import ReportMonth from '@images/report-builder/report_month.png';
import ReportTimeSince from '@images/report-builder/report_time_since.png';
class ReportBuilder extends Component {

  constructor(props) {
    super(props)

    this.state = {
      type: props.type,
    }
  }

  render() {
    const { type } = this.state;

    return (
      <section>
        <div className="report-builder__intro">
          <h1>Create Custom Table</h1>
          <p>First, you need to decide what value you would like to sort your table by. Whatever value you choose will become the first column on your table.</p>
        </div>

        <div className="report-builder__options">
          {this.props.options.map(option => (
            <ButtonOption 
              key={option.value} 
              active={type && type.value === option.value} 
              onClick={() => this.setState({type: option})}
            >
                {option.label}
            </ButtonOption>
          ))}
        </div>

        <div className="report-builder__preview">
          <img src={ReportCouncil} style={{display: type && type.value == 'council' ? 'block' : 'none', width: "469px", height: "273px" }} />
          <img src={ReportRegion} style={{display: type && type.value == 'region' ? 'block' : 'none', width: "469px", height: "273px" }} />
          <img src={ReportMonth} style={{display: type && type.value == 'month' ? 'block' : 'none', width: "469px", height: "273px" }} />
          <img src={ReportTimeSince} style={{display: type && type.value == 'time_since' ? 'block' : 'none', width: "469px", height: "273px" }} />
        </div>
        
        <div className="report-builder__actions">
          <ButtonLargeSolidGreen disabled={type === null} onClick={() => this.props.onNext(type)}>Next</ButtonLargeSolidGreen>
        </div>
      </section>
    )
  }
}

export default ReportBuilder;
