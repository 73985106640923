import React, { Component } from "react";
import { ButtonBlack, ButtonWhite, ButtonSolidGreen } from '@styles/buttons';
import ReportBuilderSelect from './ReportBuilderSelect'
import Api from "@utils/api";
import { dateRange } from "@utils/date";
import _ from "lodash";
class ReportBuilderAddColumn extends Component {

  constructor(props) {
    super(props)

    this.state = {
      column: {
        statistic: null,
        filter: null,
        year: null,
        council_id: null,
        council_name: null,
        councilOptions: [],
        data: [],
      },
    }
  }

  componentDidMount() {
      Api.get(`/councils`)
        .then(response => {
          this.setState({councilOptions: _.sortBy(response.data.map(c => ({value: c.id, label: c.name})), 'label')})
        })
  }

  render() {
    const { column, councilOptions } = this.state;
    const { report } = this.props;

    const statisticOptions = [
      {label: "Interviews Completed", value: "questionnaire_complete_count"},
      {label: "Incomplete Interviews", value: "questionnaire_incomplete_count"},
      {label: "Average time to Complete", value: "average_time_taken"},
      {label: "Average No. Trusted Connections", value: "average_connections_count"},
    ].filter(o => report.report_type && report.report_type.statistics.includes(o.value))

    const filterOptions = [
      {label: "None", value: null},
      {label: "With Social Workers", value: "social_workers"},
      {label: "With Coordinators", value: "coordinators"},
      {label: "For those Receiving Lifelong Links", value: "receiving_lll"},
      {label: "For those Not Receiving Lifelong Links", value: "not_receiving_lll"},
      {label: "For a Single Council", value: "council"},
      {label: "For a Single Year", value: "year"},
      {label: "For a Single Month", value: "month"},
    ].filter(o => report.report_type && report.report_type.filters.includes(o.value))

    const yearOptions = _.reverse(_.range(2018, (new Date()).getFullYear()+1, 1).map(year => {
      return {label: year.toString(), value: year}
    }))

    const monthOptions = dateRange(new Date(), new Date('August 1, 2018')).map(month => {
      return {label: month, value: month}
    })

    return (
      <div className="report-builder__add_column">
        <div>
          <h1>{report.name || "Create Custom Table"}</h1>

          <div className="report-builder__add_column__options">
            <div className="option">
              <label>Statistic</label>
              <ReportBuilderSelect
                options={statisticOptions}
                options={statisticOptions}
                onChange={option => this.setState({column: { ...column, statistic: option.value}})}
                value={statisticOptions.find(s => s.value === column.statistic)}
                placeholder="Choose a Statistic"
              />

            </div>
            
            { column.statistic 
              ? <div className="option">
                  <label>Optional Additional filter</label>
                  <ReportBuilderSelect
                    options={filterOptions}
                    onChange={option => this.setState({column: { ...column, filter: option.value}})}
                    value={filterOptions.find(s => s.value === column.filter)}
                  />

                </div>
              : <div className="option empty"></div>
            }
  
            { column.filter === 'year' &&
              <div className="option">
                <label>Year</label>
                <ReportBuilderSelect
                  options={yearOptions}
                  onChange={option => this.setState({column: { ...column, year: option.value}})}
                  value={yearOptions.find(s => s.value === column.year)}
                />
              </div>
            }

            { column.filter === 'month' &&
              <div className="option">
                <label>Month</label>
                <ReportBuilderSelect
                  options={monthOptions}
                  onChange={option => this.setState({column: { ...column, month: option.value}})}
                  value={monthOptions.find(s => s.value === column.month)}
                />
              </div>
            }

            { column.filter === 'council' &&
              <div className="option">
                <label>Council</label>
                <ReportBuilderSelect
                  options={councilOptions}
                  onChange={option => this.setState({column: { ...column, council_id: option.value, council_name: option.label}})}
                  value={councilOptions.find(s => s.value === column.council)}
                />
              </div>
            }

            { !['council', 'year', 'month'].includes(column.filter) && <div className="option empty"></div>}

          </div>
        </div>

        <div className="report-builder__add_column__actions">
          <ButtonWhite onClick={this.props.onCancel}>Cancel</ButtonWhite>
          <ButtonSolidGreen 
            disabled={
              !column.statistic ||
              (column.filter === 'year' && !column.year) ||
              (column.filter === 'month' && !column.month) ||
              (column.filter === 'council' && !column.council_id)
            } 
            onClick={() => this.props.onAddColumn(column)}>
              Add Column
            </ButtonSolidGreen>
        </div>
      </div>
    )
  }
}

export default ReportBuilderAddColumn;

