import React, { Component } from "react";
import { ButtonWhite, ButtonRed } from '@styles/buttons';

class DashboardQuestions extends Component {

  constructor(props) {
    super(props)

    this.state = {
      receiving_lll: true,
      not_receiving_lll: true,
    }
  }

  setReceivingLLL = () => {
    if (!this.state.not_receiving_lll)
      return 

    this.setState({receiving_lll: !this.state.receiving_lll})
  }

  setNotReceivingLLL = () =>  {
    if (!this.state.receiving_lll)
      return 

    this.setState({not_receiving_lll: !this.state.not_receiving_lll})
  }

  render() {

    return (
      <section>

        <div style={{marginBottom: 30}}>
          <ButtonWhite active={this.state.receiving_lll} onClick={this.setReceivingLLL}>Receiving Lifelong Links</ButtonWhite>
          <ButtonRed style={{marginLeft: 30}} active={this.state.not_receiving_lll} onClick={this.setNotReceivingLLL}>Not receiving Lifelong Links</ButtonRed>
        </div>

        <table className="dashboard__table">
          <thead>
            <tr>
              <th className="col-25 align-left col-25 align-left">Council Name</th>
              <th className="col-25 align-right">Number of Young People</th>
              <th className="col-25 align-right">Average Number of Connections</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map(d => (
              <tr>
              <td className="col-50 align-left">{d.name}</td>
              <td className="col-25 align-right">
                {this.state.receiving_lll && <span className="lll_count">{d.lll_people_count}</span>}
                {this.state.not_receiving_lll && <span className="non_lll_count">{d.non_lll_people_count}</span>}
              </td>
              <td className="col-25 align-right">
                {this.state.receiving_lll && <span className="lll_count">{Math.round(d.lll_connections_count)}</span>}
                {this.state.not_receiving_lll && <span className="non_lll_count">{Math.round(d.non_lll_connections_count)}</span>}
              </td>
            </tr>
            ))}
          </tbody>
        </table>

      </section>

    )
  }
}

export default DashboardQuestions;
