import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

class CopyButton extends React.Component {
  state = {
    copied: false,
  };

  render() {
    return (
      <div style={{display: "flex", flexDirection: "row"}}>
        <CopyToClipboard 
          text={this.props.text}
          onCopy={() => this.setState({copied: true})}>
            <button type="button" className="dashboard__remote-link__buttons__button copy">
              <i className="icon icon--copy--green"></i>
            <span>Copy Link</span>
          </button>
        </CopyToClipboard>

        {this.state.copied ? <span style={{color: '#FF6F45', padding: '10px'}}>Copied</span> : null}
      </div>
    );
  }
}

export default CopyButton;