import React from "react";
import {
  ToggleSwitchContainer,
  ToggleSwitchOption,
  ToggleSwitchSlider,
  ToggleSwitchSliderThumb
} from '@styles/buttons';

const ToggleSwitch = ({ leftOption, rightOption, onClick, toggled }) => (
  <ToggleSwitchContainer onClick={onClick}>
    <ToggleSwitchOption off={!toggled}>
      <p>{leftOption}</p>
    </ToggleSwitchOption>

    <ToggleSwitchSlider>
      <ToggleSwitchSliderThumb toggled={!toggled}></ToggleSwitchSliderThumb>
    </ToggleSwitchSlider>

    <ToggleSwitchOption off={toggled}>
      <p>{rightOption}</p>
    </ToggleSwitchOption>
  </ToggleSwitchContainer>
);

export default ToggleSwitch;
