import styled from 'styled-components';

export const Error = styled.div`
  margin: ${props => props.position ? {under: "12px 0 0", above: "0 0 12px" }[props.position] : "12px 0"};
  p {
    font-size: 13px;
    color: #FF6F45;
    font-weight: 400;
  }
`
