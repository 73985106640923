import React, { Component } from 'react';
import ActionModal from '@components/ActionModal'
import Api from "@utils/api"
import { ButtonWhite, ButtonSolidGreenConfirm } from '@styles/buttons';

class ReportBuilderSaveModal extends Component {

  constructor(props) {
    super(props)

    this.state = {
      display: false,
      report: { ...this.props.report }
    }
  }

  handleSave = () => {
    const { report } = this.state;
    const columns = report.columns.map(c => ({statistic: c.statistic, filter: c.filter, year: c.year, council_id: c.council_id, council_name: c.council_name, month: c.month}))

    if (report.id) {
      Api.put(`/reports/${report.id}`, { report : {
        report_type: report.report_type.value,
        name: report.name,
        columns: columns,
      } } )
      .then(response => {
        this.setState({display: false})
        this.props.onSave({name: report.name })
      })
      .catch(error => {
        console.error(error)
        window.alert("Sorry, something went wrong")
      })    
    } else {
      // Create Report
      Api.post('/reports', { report : {
        report_type: report.report_type.value,
        name: report.name,
        columns: columns,
      } } )
      .then(response => {
        window.location = `/frg/reports/${response.data.id}`
      })
      .catch(error => {
        console.error(error)
        window.alert("Sorry, something went wrong")
      })    

    }
  }

  render() {
    const { display, report } = this.state;

    return (
      <ActionModal 
        display={display} 
        trigger={<ButtonWhite border onClick={() => this.setState({display: true})}>Save Report</ButtonWhite>}
        onClose={() => this.setState({display: false})}
      >
        <div className="save-modal">
          <input 
            type="text" 
            name="name" 
            placeholder="Enter report name"
            value={report.name} 
            onChange={e => this.setState({report: {...report, name: e.target.value}})}  
          />
          <ButtonSolidGreenConfirm onClick={this.handleSave}>Save</ButtonSolidGreenConfirm>
        </div>

    </ActionModal>
    )
  }
}

export default ReportBuilderSaveModal;
