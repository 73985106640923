import React, { Component } from 'react';
import FRGSelect from '@components/FRGSelect'

class RailsSelectInput extends Component {

  constructor(props) {
    super(props)

    this.state = {
      value : props.value,
    }
  }

  render() {
    const { value } = this.state;
    const { options, name, isDisabled } = this.props;

    return (
      <div>
        <input name={name} type="hidden" value={value && value.value ? value.value : options[0].value} />
        <FRGSelect
          options={options}
          onChange={option => this.setState({value: option})}
          value={value || options[0]}
          isDisabled={isDisabled}
        />
      </div>
    )
  }
}

export default RailsSelectInput;
