import React, { Component } from 'react';
import FRGSelect from '@components/FRGSelect';

class FormSelectField extends Component {

  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value || 0
    }
  }

  render() {
    return (
      <>
        <input name={this.props.name} type="hidden" value={this.state.value} />
        <FRGSelect
          options={this.props.options}
          value={this.props.options.find(s => s.value === this.state.value)}
          onChange={({value, label}) => this.setState({value: value})}
        />
      </>
    )
  }
}

export default FormSelectField;
